<template>
  <div class="fd-layout-content" :class="{ collapse: collapse }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    collapse: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.fd-layout-content {
  padding: 80px 0 32px 46px;
  // overflow-x: hidden;
  transition: 0.5s;
  display: block;
  width: 100%;

  .animatedBox {
    transition: 0.5s;
    width: 100%;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: 0.5s;
  }
  .slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    position: absolute;
    transform: translateY(15%);
  }

  &.collapse {
    padding: 80px 0 28px 251px;
  }

  @media #{$breakpoint-down-xs} {
    margin: 66px 0 0 0;

    &:collapse {
      margin: 66px 0 32px 239px;
      padding: 0;
    }
  }
  @media #{$breakpoint-down-sm} {
    margin: 82px 0 0 0;
    position: relative;
    padding: 0;
    &.collapse {
      margin: 82px 0 32px 251px;
      padding: 5px;
    }
  }
}
</style>
